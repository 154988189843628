import {
  OrderStatusEnum,
  OrderStatusUpdateParse,
} from "@/@core/utils/enums/order.enum";

export default function useModal() {
  const resolveStatusOptions = (status) => {
    switch (status) {
      case OrderStatusEnum.Waiting_Quote:
        return [
          {
            label: OrderStatusUpdateParse[OrderStatusEnum.Quoted],
            value: OrderStatusEnum.Quoted,
          },
          {
            label: OrderStatusUpdateParse[OrderStatusEnum.Rejected],
            value: OrderStatusEnum.Rejected,
          },
        ];

      case OrderStatusEnum.Quoted:
        return [
          {
            label: OrderStatusUpdateParse[OrderStatusEnum.Accepted],
            value: OrderStatusEnum.Accepted,
          },
          {
            label: OrderStatusUpdateParse[OrderStatusEnum.Rejected],
            value: OrderStatusEnum.Rejected,
          },
        ];

      case OrderStatusEnum.Accepted:
        return [
          {
            label: OrderStatusUpdateParse[OrderStatusEnum.Processing],
            value: OrderStatusEnum.Processing,
          },
          {
            label: OrderStatusUpdateParse[OrderStatusEnum.Rejected],
            value: OrderStatusEnum.Rejected,
          },
        ];

      case OrderStatusEnum.Rejected:
        return [
          {
            label: OrderStatusUpdateParse[OrderStatusEnum.Waiting_Quote],
            value: OrderStatusEnum.Waiting_Quote,
          },
        ];
      case OrderStatusEnum.Processing:
        return [
          {
            label: OrderStatusUpdateParse[OrderStatusEnum.Landing],
            value: OrderStatusEnum.Landing,
          },
          {
            label: OrderStatusUpdateParse[OrderStatusEnum.Rejected],
            value: OrderStatusEnum.Rejected,
          },
        ];
      case OrderStatusEnum.Landing:
        return [
          {
            label: OrderStatusUpdateParse[OrderStatusEnum.Completed],
            value: OrderStatusEnum.Completed,
          },
          {
            label: OrderStatusUpdateParse[OrderStatusEnum.Rejected],
            value: OrderStatusEnum.Rejected,
          },
        ];
      case OrderStatusEnum.Delivery:
        return [
          {
            label: OrderStatusUpdateParse[OrderStatusEnum.Completed],
            value: OrderStatusEnum.Completed,
          },
        ];
    }
  };
  return {
    resolveStatusOptions,
  };
}
