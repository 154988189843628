var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [(_vm.order)?_c('b-modal',{ref:"modal",attrs:{"title":"Cập nhật trang thái","centered":"","cancel-variant":"outline-secondary","ok-disabled":!valid &&
      !_vm.isCancel &&
      ((_vm.order.status === _vm.OrderStatusEnum.Waiting_Quote &&
        _vm.statusDefault.value !== _vm.OrderStatusEnum.Rejected) ||
        (_vm.order.status === _vm.OrderStatusEnum.Processing &&
          _vm.statusDefault.value !== _vm.OrderStatusEnum.Rejected))},on:{"ok":_vm.handleUpdateOrderStatus,"cancel":_vm.definedModal,"hide":_vm.hideModel},scopedSlots:_vm._u([{key:"modal-cancel",fn:function(){return [_c('span',[_vm._v("Từ chối")])]},proxy:true},{key:"modal-ok",fn:function(){return [(_vm.loading)?_c('Spinner'):_c('span',[_vm._v("Chấp nhận")])]},proxy:true}],null,true)},[(!_vm.isCancel && _vm.order.status !== _vm.OrderStatusEnum.Completed)?_c('b-row',[_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"8","x":""}},[_c('label',[_vm._v("Trạng Thái")]),_c('v-select',{attrs:{"clearable":false,"options":_vm.statusOptions},on:{"input":_vm.resetForm},model:{value:(_vm.statusDefault),callback:function ($$v) {_vm.statusDefault=$$v},expression:"statusDefault"}})],1)],1):_vm._e(),(_vm.isCancel)?_c('div',[_c('b-row',[_c('div',{staticClass:"m-1"},[_c('p',[_vm._v("Xác nhận huỷ đơn hàng.")])])])],1):_c('div',[(
          _vm.order.status === _vm.OrderStatusEnum.Processing &&
          _vm.statusDefault.value !== _vm.OrderStatusEnum.Rejected
        )?_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"8"}},[_c('b-form-group',{attrs:{"label":"Khối lượng","label-for":"serviceCosts"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{directives:[{name:"currency",rawName:"v-currency",value:(_vm.config),expression:"config"}],attrs:{"id":"otherWeight"},model:{value:(_vm.otherWeight),callback:function ($$v) {_vm.otherWeight=$$v},expression:"otherWeight"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_vm._v("kg")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors.length > 0 ? "Khối lượng không hợp lệ" : null))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"8"}},[_c('b-form-group',{attrs:{"label":"Phí/kg","label-for":"serviceCosts"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{directives:[{name:"currency",rawName:"v-currency",value:(_vm.config),expression:"config"}],attrs:{"id":"otherPricePerWeight"},model:{value:(_vm.otherPricePerWeight),callback:function ($$v) {_vm.otherPricePerWeight=$$v},expression:"otherPricePerWeight"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_vm._v("đ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors.length > 0 ? "Phí/kg không hợp lệ" : null))])]}}],null,true)})],1)],1)],1):_vm._e(),(
          _vm.order.status === _vm.OrderStatusEnum.Waiting_Quote &&
          _vm.statusDefault.value !== _vm.OrderStatusEnum.Rejected
        )?_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"8"}},[_c('b-form-group',{attrs:{"label":"Phí dịch vụ","label-for":"serviceCosts"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{directives:[{name:"currency",rawName:"v-currency",value:(_vm.config),expression:"config"}],attrs:{"id":"serviceCosts"},model:{value:(_vm.serviceCosts),callback:function ($$v) {_vm.serviceCosts=$$v},expression:"serviceCosts"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_vm._v("%")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors.length > 0 ? "Phí dịch vụ không hợp lệ" : null))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"8"}},[_c('b-form-group',{attrs:{"label":"Phí vận chuyển","label-for":"serviceCosts"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{directives:[{name:"currency",rawName:"v-currency",value:(_vm.config),expression:"config"}],attrs:{"id":"serviceCosts"},model:{value:(_vm.shipCosts),callback:function ($$v) {_vm.shipCosts=$$v},expression:"shipCosts"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_vm._v("¥")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors.length > 0 ? "Phí vận chuyển không hợp lệ" : null))])]}}],null,true)})],1)],1)],1):_vm._e(),_c('b-row',[(_vm.order.status === _vm.OrderStatusEnum.Landing)?_c('div',{staticClass:"m-1"},[_c('p',[_vm._v("Xác nhận hoàn tất đơn hàng.")])]):_vm._e()])],1)],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }