<template>
  <validation-observer #default="{ valid }">
    <b-modal
      v-if="order"
      ref="modal"
      title="Cập nhật trang thái"
      centered
      cancel-variant="outline-secondary"
      @ok="handleUpdateOrderStatus"
      :ok-disabled="
        !valid &&
        !isCancel &&
        ((order.status === OrderStatusEnum.Waiting_Quote &&
          statusDefault.value !== OrderStatusEnum.Rejected) ||
          (order.status === OrderStatusEnum.Processing &&
            statusDefault.value !== OrderStatusEnum.Rejected))
      "
      @cancel="definedModal"
      @hide="hideModel"
    >
      <b-row v-if="!isCancel && order.status !== OrderStatusEnum.Completed">
        <b-col cols="12" md="8" x class="mb-md-0 mb-2">
          <label>Trạng Thái</label>
          <v-select
            :clearable="false"
            v-model="statusDefault"
            :options="statusOptions"
            @input="resetForm"
          />
        </b-col>
      </b-row>

      <!-- Mã TaoBao -->
      <div v-if="isCancel">
        <b-row>
          <div class="m-1">
            <p>Xác nhận huỷ đơn hàng.</p>
          </div>
        </b-row>
      </div>
      <div v-else>
        <!-- Tiền cân -->
        <b-row
          class="mt-2"
          v-if="
            order.status === OrderStatusEnum.Processing &&
            statusDefault.value !== OrderStatusEnum.Rejected
          "
        >
          <b-col cols="12" md="8" class="mb-md-0 mb-2">
            <b-form-group label="Khối lượng" label-for="serviceCosts">
              <validation-provider rules="required" v-slot="{ errors }">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-currency="config"
                    id="otherWeight"
                    v-model="otherWeight"
                  />
                  <b-input-group-append is-text>kg</b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{
                  errors.length > 0 ? "Khối lượng không hợp lệ" : null
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="8" class="mb-md-0 mb-2">
            <b-form-group label="Phí/kg" label-for="serviceCosts">
              <validation-provider rules="required" v-slot="{ errors }">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-currency="config"
                    id="otherPricePerWeight"
                    v-model="otherPricePerWeight"
                  />
                  <b-input-group-append is-text>đ</b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{
                  errors.length > 0 ? "Phí/kg không hợp lệ" : null
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- totalQuotePrice -->
        <b-row
          class="mt-2"
          v-if="
            order.status === OrderStatusEnum.Waiting_Quote &&
            statusDefault.value !== OrderStatusEnum.Rejected
          "
        >
          <b-col cols="12" md="8" class="mb-md-0 mb-2">
            <b-form-group label="Phí dịch vụ" label-for="serviceCosts">
              <validation-provider rules="required" v-slot="{ errors }">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-currency="config"
                    id="serviceCosts"
                    v-model="serviceCosts"
                  />
                  <b-input-group-append is-text>%</b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{
                  errors.length > 0 ? "Phí dịch vụ không hợp lệ" : null
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="8" class="mb-md-0 mb-2">
            <b-form-group label="Phí vận chuyển" label-for="serviceCosts">
              <validation-provider rules="required" v-slot="{ errors }">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-currency="config"
                    id="serviceCosts"
                    v-model="shipCosts"
                  />
                  <b-input-group-append is-text>¥</b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{
                  errors.length > 0 ? "Phí vận chuyển không hợp lệ" : null
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <div class="m-1" v-if="order.status === OrderStatusEnum.Landing">
            <p>Xác nhận hoàn tất đơn hàng.</p>
          </div>
        </b-row>
      </div>

      <template #modal-cancel>
        <span>Từ chối</span>
      </template>
      <template #modal-ok>
        <Spinner v-if="loading" />
        <span v-else>Chấp nhận</span>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  OrderStatusEnum,
  OrderStatusParse,
} from "@/@core/utils/enums/order.enum";
import { formatCurrency } from "@/helpers/untils";
import useToastHandler from "@/services/toastHandler";
import Spinner from "@core/components/Spinner";
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
  VBModal,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import vSelect from "vue-select";
import { mapActions, mapState } from "vuex";
import useModal from "./useModal";
import { functionProp } from "@/helpers/props";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BTable,
    BPagination,
    vSelect,
    BBadge,
    BButton,
    ValidationObserver,
    ValidationProvider,
    Spinner,
    BInputGroupAppend,
    BInputGroup,
  },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    order: {
      type: Object,
      default: null,
    },
    refreshOrder: {
      type: Function,
      default: () => {},
    },
    handleHideModal: functionProp(),
  },
  data() {
    return {
      statusOptions: [],
      statusDefault: null,
      // status = quote
      serviceCosts: undefined,
      shipCosts: undefined,
      totalOtherPrice: undefined,
      // status = processing
      otherWeight: undefined,
      otherPricePerWeight: undefined,
      isCancel: false,
    };
  },
  async created() {
    // defined status select
    this.definedModal();
  },

  computed: {
    ...mapState({
      orderLoading: (s) => {
        return s.apps.order.orders.loading;
      },
      loading: (s) => {
        return s.apps.order.update.loading;
      },
    }),
    config() {
      return {
        currency: null,
        precision: { min: 0, max: 2 },
      };
    },
  },
  methods: {
    ...mapActions({
      updateOrderStatus: "order/update/updateOrderStatus",
    }),
    handleShowModal(isCancel) {
      this.isCancel = isCancel;
      this.resetForm();
      this.$refs.modal.show();
    },
    definedModal() {
      // defined status select
      const { resolveStatusOptions } = useModal();
      this.statusOptions = resolveStatusOptions(this.order.status);
      this.statusDefault = { ...this.statusOptions?.[0] };
    },
    async handleUpdateOrderStatus(event) {
      event.preventDefault();

      const { orderId, status } = this.order;
      const { otherWeight, otherPricePerWeight } = this;

      const updateStatus = await this.updateOrderStatus({
        serviceCosts:
          this.serviceCosts &&
          Number(this.handleParseCurrency(this.serviceCosts)),
        shipCosts:
          this.shipCosts && Number(this.handleParseCurrency(this.shipCosts)),
        totalOtherPrice: this.totalOtherPrice && Number(this.totalOtherPrice),
        status: this.isCancel
          ? OrderStatusEnum.Rejected
          : this.statusDefault.value,
        ...(status == OrderStatusEnum.Processing
          ? {
              otherWeight: +this.handleParseCurrency(otherWeight),
              otherPricePerWeight:
                +this.handleParseCurrency(otherPricePerWeight),
            }
          : {}),
        orderId,
      });

      if (updateStatus) {
        this.showToastSuccess("Thành công", "Cập nhật trạng thái thành công.");
        this.$refs.modal.hide();
        await this.refreshOrder();
        this.definedModal();
      }
      this.$refs?.modal?.hide();
    },
    handleParseCurrency(amount) {
      return this.$parseCurrency(amount, {
        locale: "en",
        currency: null,
      });
    },
    hideModel() {
      if (!this.loading) {
        this.definedModal();
        this.handleHideModal();
      }
    },
    resetForm() {
      this.serviceCosts = undefined;
      this.shipCosts = undefined;
      this.totalOtherPrice = undefined;
      this.otherWeight = undefined;
      this.otherPricePerWeight = undefined;
    },
  },
  setup() {
    return {
      ...useModal(),
      ...useToastHandler(),
      OrderStatusParse,
      OrderStatusEnum,
      formatCurrency,
    };
  },
};
</script>

<style lang="scss">
.tbody-tr-class td[aria-colindex="1"] {
  width: 210px;
}

@import "@core/scss/vue/libs/vue-select.scss";
</style>
