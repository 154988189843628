<template>
  <b-modal
    v-if="order"
    ref="modal"
    title="Cập nhật ghí chú"
    centered
    cancel-variant="outline-secondary"
    @ok="handleUpdateOrder"
    :ok-disabled="isDisableBtnModal()"
    @hide="() => handleHideModal(false)"
  >
    <!-- @hide="handleUpdateOrderList(indexOrderList, false)" -->
    <b-col cols="12" md="12" class="mb-md-0 mb-2">
      <div v-if="!isPayment">
        <div class="d-flex">
          <label for="bio-area">Nội dung ghi chú</label>
        </div>
        <b-form-textarea id="body" v-model="sumNoteAdmin" rows="10" />
      </div>
      <div v-else>
        <span><p>Xác nhận thanh toán đơn hàng.</p></span>
      </div>
    </b-col>

    <template #modal-cancel>
      <span>Từ chối</span>
    </template>
    <template #modal-ok>
      <Spinner
        v-if="
          isPayment
            ? updateOrderInfoLoading || acceptPaymentOrderLoading
            : updateOrderLoading
        "
      />
      <span v-else>Chấp nhận</span>
    </template>
  </b-modal>
</template>

<script>
import {
OrderStatusEnum,
OrderStatusParse,
} from "@/@core/utils/enums/order.enum";
import { functionProp, objectProp } from "@/helpers/props";
import { formatCurrency } from "@/helpers/untils";
import useToastHandler from "@/services/toastHandler";
import Spinner from "@core/components/Spinner";
import {
BBadge,
BButton,
BCard,
BCol,
BFormGroup,
BFormInput,
BFormTextarea,
BInputGroup,
BInputGroupAppend,
BPagination,
BRow,
BTable,
VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapState } from "vuex";
import useModal from "./useModal";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BTable,
    BPagination,
    vSelect,
    BBadge,
    BButton,
    Spinner,
    BInputGroupAppend,
    BInputGroup,
    BFormTextarea,
  },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    order: objectProp(),
    handleRefreshAdminNote: functionProp(),
    handleUpdateOrderInfo: functionProp(),
    handleUpdateOrderList: functionProp(),
  },
  data() {
    return {
      sumNoteAdmin: "",
      isPayment: false,
      indexOrderList: undefined,
    };
  },

  computed: {
    ...mapState({
      updateOrderLoading: (s) => {
        return s.apps.order.updateOrder.loading;
      },
      updateOrderInfoLoading: (s) => s.apps.order.order.loading,
      acceptPaymentOrderLoading: (s) => s.apps.order.orderUserFilter.loading,
    }),
  },
  created() {
    if (!this.order.sumNoteAdmin) {
      this.order.sumNoteAdmin = "";
    }
    this.sumNoteAdmin = this.order.sumNoteAdmin;
  },
  methods: {
    ...mapActions({
      updateOrder: "order/updateOrder/updateOrder",
      acceptPaymentOrder: "order/orderUserFilter/AcceptPaymentOrder",
    }),
    async handleUpdateOrder(event) {
      event.preventDefault();
      if (this.isPayment) return await this.handleAcceptPaymentOrder();
      const { orderId } = this.order;
      const res = await this.updateOrder({
        orderId,
        sumNoteAdmin: this.sumNoteAdmin,
      });
      if (!res) return;
      this.$refs.modal.hide();
      this.handleRefreshAdminNote(this.sumNoteAdmin);
      this.showToastSuccess(null, "Cập nhật ghi chú thành công.");
    },
    async handleAcceptPaymentOrder() {
      const { orderId } = this.order;
      const res = await this.acceptPaymentOrder({
        orderId,
      });
      if (res) {
        this.showToastSuccess(null, "Thanh toán đơn hàng thành công.");
        await this.handleUpdateOrderInfo();
      }
      await this.$refs.modal.hide();
      this.handleHideModal(!!res);
    },
    handleHideModal(isSuccess) {
      if (!this.acceptPaymentOrderLoading) {
        this.handleUpdateOrderList(this.indexOrderList, isSuccess);
      }
    },
    handleShowModal(isPayment) {
      this.isPayment = !!isPayment;
      this.$refs.modal.show();
    },
    handleShowModalFromOrderList(indexOrderList) {
      this.indexOrderList = indexOrderList;
      this.handleShowModal(true);
    },
    isDisableBtnModal() {
      return this.order.sumNoteAdmin == this.sumNoteAdmin && !this.isPayment;
    },
  },
  setup() {
    return {
      ...useModal(),
      ...useToastHandler(),
      OrderStatusParse,
      OrderStatusEnum,
      formatCurrency,
    };
  },
};
</script>

<style lang="scss">
.tbody-tr-class td[aria-colindex="1"] {
  width: 210px;
}

@import "@core/scss/vue/libs/vue-select.scss";
</style>
